<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="main-box">
				<div class="mem_contents_header">
					<div class="title"><span class="mem_info"></span><p>회원가입/수요기업</p></div>
					<div class="reg-step"><img src="/images/mem/process_bar_01.png" alt="절차"/></div>
				</div>
				<div class="mem_contents_body">
					<div class="body-top"><hr/><p>하이프로는 업계의 검증된 인재와 프로젝트를 연결 해 드립니다.</p><hr/></div>
					<div class="info-box account">
						<div class="phone-img account">
							수요기업 계정 선택
							<div class="select-box">
								<input type="radio" class="account" id="00" v-model="mberDivCd" value="21" checked>
								<label for="00">
									<span class="rd"></span>
									관리 총괄
								</label>
								<span class="account">/</span>
								<input type="radio" class="account" id="01" v-model="mberDivCd" value="22">
								<label for="01">
									<span class="rd"></span>
									프로젝트 담당
								</label>
							</div>
							<div class="phone-info account">
								선택하신 계정으로 회원가입을 진행하시겠습니까?<br/>
								※ ‘프로젝트 담당’으로 등록 시 ‘관리 총괄’이 가입 되어 있어야 합니다!
							</div>
						</div>
						<div class="phone-btn account">
							<div class="btn-prv" @click="btnCancel()">아니오</div>
							<div class="btn-crt" @click="pageMove()">다음</div>
						</div>
					</div>
					<p class="account">※ 회원유형에 따라 가입 절차에 차이가 있습니다.  ‘관리 총괄’ 및 ‘프로젝트 담당’ 중 선택해 등록하시길 바랍니다.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberDivCd : '21'
		}
	},

	methods : {
		pageMove() {
			var param = {
				mberDivCd : this.mberDivCd
			};

			this.$router.push({name:'MEM004M01', params:{forwardData : param}});
		},
		
		btnCancel() {
			this.$router.replace('MEM003M01');
		}
	}
}
</script>